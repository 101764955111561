<template>
   <v-layout align-start>
      <v-flex>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>

         <!-- TOOLBAR -->
         <v-toolbar flat color="white" class="pt-3">
            <v-toolbar-title style="font-weight: 500">
               {{ toolbarTitle }}
            </v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <div class="mt-6 mx-2" style="width: 225px">
               <v-select
                  v-if="verNuevo == 0"
                  v-model="selectCiclo"
                  :items="cmbCiclo"
                  color="primary"
                  item-text="nombre"
                  item-value="cicloId"
                  label="Ciclo"
                  placeholder="Seleccionar..."
               >
               </v-select>
            </div>
            <v-spacer></v-spacer>
            <v-text-field
               v-if="verNuevo == 0"
               ref="txtBusqueda"
               v-model="searchComputed"
               append-icon="fa-search"
               class="mt-3"
               color="primary"
               label="Busqueda"
               single-line
               hide-deatails
            ></v-text-field>
            <v-spacer></v-spacer>

            <!-- DIALOG EDITAR talonario -->
            <v-dialog v-model="dialogEditar" persistent max-width="1000px">
               <v-form>
                  <v-card>
                     <v-card-text
                        class="text--primary"
                        style="margin-top: -20px"
                     >
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <span class="text-h5">Editar Talonario</span>
                                 <v-divider class="ml-2" vertical></v-divider>
                              </v-flex>
                              <v-flex xs12 sm4>
                                 <v-text-field
                                    v-model="numeroTalonario"
                                    label="Número de Talonario"
                                    color="primary"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm4>
                                 <v-text-field
                                    v-model="carnet"
                                    color="primary"
                                    label="Carnet"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm4>
                                 <v-text-field
                                    v-model="alumno"
                                    label="Alumno"
                                    color="primary"
                                    readonly
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm4>
                                 <CustomDatePicker
                                    :date="fecha"
                                    readonly
                                    label="Fecha creación"
                                    min="2015-01"
                                    @updateFecha="fecha = $event"
                                 />
                              </v-flex>
                              <v-flex xs12 sm4>
                                 <v-select
                                    v-model="selectEstadoTalonario"
                                    :readonly="
                                       selectEstadoTalonario != 1 ||
                                       editedIndex == 0
                                    "
                                    :items="cmbEstadoTalonario"
                                    color="primary"
                                    item-text="nombre"
                                    item-value="estadoTalonarioId"
                                    label="Estado de Talonario"
                                    placeholder="Seleccionar..."
                                 ></v-select>
                              </v-flex>
                              <v-flex xs12 sm4>
                                 <CustomDatePicker
                                    readonly
                                    :date="fechaAnulacion"
                                    label="Fecha anulación"
                                    min="2015-01"
                                    @updateFecha="fechaAnulacion = $event"
                                 />
                              </v-flex>
                              <v-flex xs12 sm4>
                                 <v-autocomplete
                                    v-model="sedeNombre"
                                    readonly
                                    :items="cmbSede"
                                    color="primary"
                                    item-text="nombre"
                                    item-value="sedeId"
                                    label="Sede"
                                    placeholder="Seleccionar..."
                                 ></v-autocomplete>
                              </v-flex>
                              <v-flex xs12 sm4>
                                 <v-text-field
                                    v-model="totalTalonario"
                                    readonly
                                    label="Total Talonario"
                                    color="primary"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm4>
                                 <v-text-field
                                    v-model="cicloDescripcion"
                                    readonly
                                    color="primary"
                                    label="Ciclo"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm4>
                                 <v-text-field
                                    v-model="configuracionPago"
                                    readonly
                                    color="primary"
                                    label="Configuración de Pago"
                                 ></v-text-field>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>
                     <v-card-actions
                        style="
                           margin-top: -40px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary"
                           text
                           @click.native="closeEditTalonario"
                           >Cancelar</v-btn
                        >
                        <v-btn
                           color="primary text--white"
                           @click.native="guardarTalonario"
                           >Guardar</v-btn
                        >
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-dialog>

            <!-- DIALOG EDITAR Cuota -->
            <v-dialog v-model="dialogEditarCuota" persistent max-width="1000px">
               <v-form>
                  <v-card>
                     <v-card-text
                        class="text--primary"
                        style="margin-top: -20px"
                     >
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <span class="text-h5">Editar Cuota</span>
                                 <v-divider class="ml-2" vertical></v-divider>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="numeroCuota"
                                    readonly
                                    label="Cuota"
                                    color="primary"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="totalPagar"
                                    readonly
                                    label="A pagar"
                                    color="primary"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="totalMora"
                                    readonly
                                    label="Mora"
                                    color="primary"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <v-text-field
                                    v-model="npe"
                                    readonly
                                    label="NPE"
                                    color="primary"
                                 ></v-text-field>
                              </v-flex>
                              <v-flex>
                                 <v-autocomplete
                                    v-model="selectEstadoDetalle"
                                    :items="cmbEstadoDetalle"
                                    color="primary"
                                    item-text="nombre"
                                    item-value="estadoTalonarioId"
                                    label="Estado"
                                    no-data-text="Sin resultados"
                                    placeholder="Seleccionar..."
                                 ></v-autocomplete>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>
                     <v-card-actions
                        style="
                           margin-top: -40px;
                           margin-right: 20px;
                           padding-bottom: 20px;
                        "
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary"
                           text
                           @click.native="closeCuotaPago"
                           >Cancelar</v-btn
                        >
                        <v-btn
                           color="primary text--white"
                           @click.native="saveDetalleTalonario"
                           >Guardar</v-btn
                        >
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-dialog>

            <v-dialog v-model="dialogMasivo" persistent max-width="500px">
               <v-form>
                  <v-card>
                     <v-card-text
                        class="text--primary"
                        style="margin-top: -20px"
                     >
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12 text-center>
                                 <span class="text-h5 font-weight-medium">{{
                                    titleMasivo()
                                 }}</span>
                              </v-flex>

                              <!-- Reportes de marcaciones -->
                              <v-flex xs6 text-center>
                                 <v-btn
                                    color="primary white--text"
                                    class="mr-2"
                                    @click="
                                       doing == 1
                                          ? guardarTalonario()
                                          : removerTalonario()
                                    "
                                 >
                                    Si
                                 </v-btn>
                              </v-flex>
                              <v-flex xs6 text-center>
                                 <v-btn
                                    color="secundary"
                                    class="mr-2"
                                    @click="dialogMasivo = false"
                                 >
                                    No
                                 </v-btn>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <!-- DIALOG GENERANDO REPORTE -->
                     <v-dialog
                        v-model="dialogReporteLoading"
                        persistent
                        width="300"
                     >
                        <v-card color="primary" dark class="pt-5 pb-2">
                           <v-card-text style="color: white">
                              {{ doing == 1 ? 'Generando ' : 'Eliminado ' }}
                              Talonarios..
                              <v-progress-linear
                                 indeterminate
                                 color="white"
                                 class="mb-0"
                              ></v-progress-linear>
                           </v-card-text>
                        </v-card>
                     </v-dialog>
                  </v-card>
               </v-form>
            </v-dialog>

            <!-- DIALOGO IMPRIMIR TALONARIO -->
            <v-dialog v-model="dialogPrint" persistent max-width="500px">
               <v-form>
                  <v-card>
                     <v-card-text
                        class="text--primary"
                        style="margin-top: -20px"
                     >
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12 text-center pa-4>
                                 <span class="text-h5 font-weight-medium">
                                    Imprimir talonario
                                 </span>
                              </v-flex>

                              <!-- Reportes de marcaciones -->

                              <v-flex xs12 sm6>
                                 <div style="font-size: 18px">Carnet:</div>
                                 <v-card
                                    flat
                                    color="red lighten-5"
                                    class="text-center elevation-1"
                                    style="border-radius: 10px"
                                 >
                                    <v-card-text class="py-2">
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ carnet }}</span
                                       >
                                    </v-card-text>
                                 </v-card>
                              </v-flex>
                              <v-flex xs12 sm6>
                                 <div style="font-size: 18px">Ciclo:</div>
                                 <v-card
                                    flat
                                    color="red lighten-5"
                                    class="text-center elevation-1"
                                    style="border-radius: 10px"
                                 >
                                    <v-card-text class="py-2">
                                       <span
                                          class="red--text text--darken-2 text-h6"
                                          >{{ cicloDescripcion }}</span
                                       >
                                    </v-card-text>
                                 </v-card>
                              </v-flex>
                              <v-flex xs12 sm6 text-center mt-4>
                                 <v-btn
                                    color="primary white--text"
                                    class="mr-2"
                                    block
                                    @click="
                                       generarReporteTalonario(
                                          $vuetify.breakpoint.smAndUp
                                             ? 'open'
                                             : 'save',
                                          true,
                                          filtroCuotas.trim() == ''
                                             ? null
                                             : filtroCuotas
                                       )
                                    "
                                 >
                                    Con portadas
                                 </v-btn>
                              </v-flex>
                              <v-flex xs12 sm6 text-center mt-4>
                                 <v-btn
                                    color="secundary"
                                    class="mr-2"
                                    block
                                    @click="
                                       generarReporteTalonario(
                                          $vuetify.breakpoint.smAndUp
                                             ? 'open'
                                             : 'save',
                                          false,
                                          filtroCuotas.trim() == ''
                                             ? null
                                             : filtroCuotas
                                       )
                                    "
                                 >
                                    Sin portadas
                                 </v-btn>
                              </v-flex>
                              <v-flex xs12 mt-4>
                                 <v-expansion-panels>
                                    <v-expansion-panel>
                                       <v-expansion-panel-header>
                                          <template #default="{ open }">
                                             <v-row no-gutters>
                                                <v-col cols="4"
                                                   >Filtrar cuotas</v-col
                                                >
                                                <v-col
                                                   cols="8"
                                                   class="text--secondary"
                                                >
                                                   <v-fade-transition
                                                      leave-absolute
                                                   >
                                                      <span v-if="open" key="0">
                                                         Ingrese las cuotas a
                                                         imprimir. Ejemplo:
                                                         1,3,4
                                                      </span>
                                                      <span v-else key="1">
                                                         {{
                                                            filtroCuotas.trim() ==
                                                            ''
                                                               ? 'Todas'
                                                               : filtroCuotas
                                                         }}
                                                      </span>
                                                   </v-fade-transition>
                                                </v-col>
                                             </v-row>
                                          </template>
                                       </v-expansion-panel-header>
                                       <v-expansion-panel-content>
                                          <v-text-field
                                             v-model="filtroCuotas"
                                             placeholder="Todas"
                                          ></v-text-field>
                                       </v-expansion-panel-content>
                                    </v-expansion-panel>
                                 </v-expansion-panels>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>
                     <v-card-actions
                        style="margin-left: 20px; padding-bottom: 20px"
                     >
                        <v-btn
                           color="primary"
                           text
                           width="100"
                           @click="closeDialogPrint()"
                        >
                           Salir
                        </v-btn>
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-dialog>

            <!-- DIALOG ELIMINAR talonario -->
            <v-dialog
               v-model="dialogEliminarTalonario"
               persistent
               max-width="600px"
            >
               <v-form ref="formEliminarTalonario">
                  <v-card>
                     <v-card-text
                        class="text--primary"
                        style="margin-top: -20px"
                     >
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <span class="text-h5 font-weight-medium"
                                    >Remover talonario</span
                                 >
                              </v-flex>
                              <v-flex xs12 justify-center>
                                 <span
                                    class="font-weight-regular"
                                    style="font-size: 20px"
                                 >
                                    ¿Esta seguro que desea remover la talonario?
                                 </span>
                                 <v-layout wrap style="margin-top: 10px">
                                    <v-flex xs12 sm6>
                                       <div style="font-size: 18px">
                                          Número:
                                       </div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="border-radius: 10px"
                                       >
                                          <v-card-text>
                                             <span
                                                class="red--text text--darken-2 text-h6"
                                                >{{ ntalonario }}</span
                                             >
                                          </v-card-text>
                                       </v-card>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                       <div style="font-size: 18px">
                                          Carnet:
                                       </div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="border-radius: 10px"
                                       >
                                          <v-card-text>
                                             <span
                                                class="red--text text--darken-2 text-h6"
                                                >{{ carnet }}</span
                                             >
                                          </v-card-text>
                                       </v-card>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                       <div style="font-size: 18px">
                                          Configuración de Pago:
                                       </div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="border-radius: 10px"
                                       >
                                          <v-card-text>
                                             <span
                                                class="red--text text--darken-2 text-h6"
                                                >{{ configuracionPago }}</span
                                             >
                                          </v-card-text>
                                       </v-card>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                       <div style="font-size: 18px">Ciclo:</div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="border-radius: 10px"
                                       >
                                          <v-card-text>
                                             <span
                                                class="red--text text--darken-2 text-h6"
                                                >{{ cicloNombre }}</span
                                             >
                                          </v-card-text>
                                       </v-card>
                                    </v-flex>
                                 </v-layout>
                              </v-flex>
                              <v-flex xs12 text-center>
                                 <v-icon
                                    color="primary"
                                    size="70"
                                    class="mt-2 mb-2"
                                 >
                                    fa-trash-alt
                                 </v-icon>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <v-card-actions
                        style="margin-top: -30px; padding-bottom: 20px"
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary"
                           dark
                           width="150"
                           outlined
                           @click.native="removerTalonario"
                           >Si</v-btn
                        >
                        <v-btn
                           color="primary"
                           dark
                           width="150"
                           @click.native="closeDeleteTalonario"
                           >No</v-btn
                        >
                        <v-spacer></v-spacer>
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-dialog>

            <!-- DIALOG ELIMINAR DETALLE -->
            <v-dialog
               v-model="dialogEliminarDetalle"
               persistent
               max-width="650px"
            >
               <v-form ref="formEliminarDetalle">
                  <v-card>
                     <v-card-text
                        class="text--primary"
                        style="margin-top: -20px"
                     >
                        <v-container grid-list-md>
                           <v-layout wrap>
                              <v-flex xs12>
                                 <span class="text-h5 font-weight-medium"
                                    >Remover detalle de talonario</span
                                 >
                              </v-flex>
                              <v-flex xs12 justify-center>
                                 <span
                                    class="font-weight-regular"
                                    style="font-size: 20px"
                                 >
                                    ¿Esta seguro que desea remover el detalle de
                                    talonario?
                                 </span>
                                 <v-layout wrap style="margin-top: 10px">
                                    <v-flex xs12>
                                       <div style="font-size: 18px">
                                          Articulo:
                                       </div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="
                                             border-radius: 10px;
                                             padding: 15px 25px;
                                          "
                                       >
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >hi</span
                                          >
                                       </v-card>
                                    </v-flex>
                                    <v-flex xs12 sm6>
                                       <div style="font-size: 18px">
                                          Cantidad:
                                       </div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="
                                             border-radius: 10px;
                                             padding: 15px 25px;
                                          "
                                       >
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >hi</span
                                          >
                                       </v-card>
                                    </v-flex>
                                    <v-flex xs12>
                                       <div style="font-size: 18px">
                                          Total de detalle:
                                       </div>
                                       <v-card
                                          flat
                                          color="red lighten-5"
                                          class="text-center elevation-1"
                                          style="
                                             border-radius: 10px;
                                             padding: 15px 25px;
                                          "
                                       >
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >$ 10
                                          </span>
                                       </v-card>
                                    </v-flex>
                                 </v-layout>
                              </v-flex>

                              <v-flex xs12 text-center>
                                 <v-icon
                                    color="primary"
                                    size="70"
                                    class="mt-2 mb-2"
                                 >
                                    fa-trash-alt
                                 </v-icon>
                              </v-flex>
                           </v-layout>
                        </v-container>
                     </v-card-text>

                     <v-card-actions
                        style="margin-top: -30px; padding-bottom: 20px"
                     >
                        <v-spacer></v-spacer>
                        <v-btn
                           color="primary"
                           dark
                           width="150"
                           outlined
                           @click.native="removerDetallecompra"
                           >Si</v-btn
                        >
                        <v-btn
                           color="primary"
                           dark
                           width="150"
                           @click.native="closeDeleteDetalle"
                           >No</v-btn
                        >
                        <v-spacer></v-spacer>
                     </v-card-actions>
                  </v-card>
               </v-form>
            </v-dialog>
         </v-toolbar>

         <!-- DATATABLE talonario -->
         <v-data-table
            v-if="verNuevo == 0"
            :headers="headers"
            :items="filteredTalonarios"
            :search="search"
            :footer-props="{
               itemsPerPageOptions: [10, 25],
               itemsPerPageText: 'Filas por página:'
            }"
            class="elevation-0"
         >
            <template #item.opciones="{ item }">
               <!-- OPCIONES Editar / Detalles / Eliminar -->
               <v-btn
                  text
                  small
                  fab
                  color="orange darken-4"
                  @click="showTalonarioDetalle(item)"
               >
                  <v-icon>fa-bars</v-icon>
               </v-btn>
               <v-btn
                  text
                  small
                  fab
                  color="primary"
                  @click="printTalonario(item)"
               >
                  <v-icon>fas fa-print</v-icon>
               </v-btn>
            </template>

            <!-- Filtros de busqueda -->
            <template #body.append="{ headers }">
               <tr>
                  <td :colspan="headers.length">
                     <v-layout align-center justify-start fill-height>
                        <div style="margin-right: 20px">
                           <span class="text-body-1 font-weight-medium">
                              Buscar por
                           </span>
                        </div>
                        <div style="margin-top: 5px">
                           <v-radio-group
                              v-model="radiosBusqueda"
                              row
                              @change="focusBusqueda"
                           >
                              <v-radio
                                 label="Número de talonario"
                                 color="primary"
                                 value="r-numeroTalonario"
                              ></v-radio>
                              <v-radio
                                 label="Carnet"
                                 color="primary"
                                 value="r-carnet"
                              ></v-radio>
                              <v-radio
                                 label="Alumno"
                                 color="primary"
                                 value="r-alumno"
                              ></v-radio>
                              <v-radio
                                 label="Fecha anulación"
                                 color="primary"
                                 value="r-fecha"
                              ></v-radio>
                           </v-radio-group>
                        </div>
                     </v-layout>
                  </td>
               </tr>
            </template>

            <!-- Si no hay datos, mostrar boton para listar de nuevo -->
            <template slot="no-data">
               <div v-if="talonarios.length == 0">
                  <v-container>
                     <v-layout wrap align-center>
                        <v-flex xs12 class="text-center">
                           <v-btn color="primary" @click="reset"
                              >Resetear</v-btn
                           >
                        </v-flex>
                     </v-layout>
                  </v-container>
               </div>
               <div v-if="filteredTalonarios.length == 0 && talonarios != 0">
                  <v-alert
                     dark
                     class="mt-4"
                     color="primary"
                     icon="fa-exclamation-circle"
                  >
                     La busquedad de "{{ search }}" no devolvió ningun
                     resultado.
                  </v-alert>
               </div>
            </template>
         </v-data-table>

         <!-- CONTAINER NUEVA talonario -->
         <v-container v-if="verNuevo" fluid grid-list-lg class="white pt-0">
            <v-form>
               <v-flex xs12>
                  <!-- INFORMACION talonario -->
                  <v-layout wrap class="pl-4 pr-4">
                     <v-flex xs12 sm2 class="smallForm">
                        <v-text-field
                           v-model="numeroTalonario"
                           label="Número de talonario"
                           color="primary"
                           readonly
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm3 class="smallForm">
                        <v-text-field
                           v-model="carnet"
                           color="primary"
                           label="Carnet"
                           readonly
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm5 class="smallForm">
                        <v-text-field
                           v-model="alumno"
                           label="Alumno"
                           color="primary"
                           readonly
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm2 class="smallForm">
                        <CustomDatePicker
                           :date="fecha"
                           readonly
                           label="Fecha creación"
                           min="2015-01"
                           @updateFecha="fecha = $event"
                        />
                     </v-flex>
                     <v-flex xs12 sm2 class="smallForm">
                        <v-select
                           v-model="selectEstadoTalonario"
                           :readonly="
                              selectEstadoTalonario != 1 || editedIndex == 0
                           "
                           :items="cmbEstadoTalonario"
                           color="primary"
                           item-text="nombre"
                           item-value="estadoTalonarioId"
                           label="Estado de talonario"
                           placeholder="Seleccionar..."
                        ></v-select>
                     </v-flex>
                     <v-flex xs12 sm3 class="smallForm">
                        <CustomDatePicker
                           readonly
                           :date="fechaAnulacion"
                           label="Fecha anulación"
                           min="2015-01"
                           @updateFecha="fechaAnulacion = $event"
                        />
                     </v-flex>
                     <v-flex xs12 sm5 class="smallForm">
                        <v-text-field
                           v-model="sedeNombre"
                           label="Sede"
                           color="primary"
                           readonly
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm4 class="smallForm">
                        <v-text-field
                           v-model="totalTalonario"
                           readonly
                           label="Total talonario"
                           color="primary"
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm2 class="smallForm">
                        <v-text-field
                           v-model="cicloDescripcion"
                           readonly
                           color="primary"
                           label="Ciclo"
                        ></v-text-field>
                     </v-flex>
                     <v-flex xs12 sm8 class="smallForm">
                        <v-text-field
                           v-model="configuracionPago"
                           readonly
                           color="primary"
                           label="Configuracion de pago"
                        ></v-text-field>
                     </v-flex>
                  </v-layout>
               </v-flex>
               <v-flex xs12>
                  <v-card class="borderDetallesPrimary">
                     <v-card-text>
                        <v-layout>
                           <v-flex xs12>
                              <span class="text-h6">{{ detalleTitle }}</span>
                           </v-flex>
                        </v-layout>
                        <v-layout wrap>
                           <!-- DATATABLE DETALLES DE talonario -->
                           <v-flex xs12 sm12 style="margin-top: -20px">
                              <v-data-table
                                 :headers="headersDetalle"
                                 :items="detalles"
                                 :footer-props="{
                                    itemsPerPageOptions: [10, 25, 50],
                                    itemsPerPageText: 'Filas por página:'
                                 }"
                                 hidden-actions
                              >
                                 <template #no-data>
                                    <h3>talonario Sin Cuotas</h3>
                                 </template>
                                 <template #item.estado="{ item }">
                                    <v-chip :color="getColorEstado(item)" dark>
                                       <span>{{ item.estado }}</span>
                                    </v-chip>
                                 </template>
                              </v-data-table>
                           </v-flex>
                        </v-layout>
                     </v-card-text>
                  </v-card>
               </v-flex>
               <v-flex xs12>
                  <v-layout style="margin-top: 20px">
                     <!-- OPCIONES talonario -->
                     <v-btn
                        color="primary"
                        class="ml-2"
                        @click="ocultarNuevo()"
                     >
                        Salir
                     </v-btn>
                  </v-layout>
               </v-flex>
            </v-form>
         </v-container>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import debounce from 'lodash/debounce'
import formatters from '../mixins/formattersLib'
import { Base64 } from 'js-base64'
const CustomDatePicker = () => import('./custom/CustomDatePicker')
const getReporteLib = () => import('../mixins/reporteLib')

export default {
   name: 'Talonarios',
   components: {
      CustomDatePicker
   },
   mixins: [formatters],
   data: (vm) => ({
      // Variables
      dialogEditar: false,
      editedIndex: -1,
      headers: [
         {
            text: 'N° Talonario',
            value: 'numero',
            width: '100',
            class: 'titulo'
         },
         {
            text: 'Ciclo',
            value: 'ciclo',
            width: '100',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Sede',
            value: 'sedeNombre',
            width: '100',
            class: 'titulo'
         },
         {
            text: 'Carnet',
            value: 'carnet',
            width: '80',
            class: 'titulo'
         },
         {
            text: 'Alumno',
            value: 'alumno',
            width: '80',
            class: 'titulo'
         },
         {
            text: 'Estado',
            value: 'estado',
            width: '100',
            align: 'center',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            sortable: false,
            width: '160',
            align: 'right',
            class: 'titulo'
         }
      ],
      headersDetalle: [
         {
            text: 'N° Cuota',
            value: 'numeroCuota',
            width: '185',
            class: 'titulo'
         },
         {
            text: 'A pagar',
            value: 'totalPagar',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Fecha de pago',
            value: 'fechaPago',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Fecha de vencimiento',
            value: 'fechaVencimiento',
            class: 'titulo',
            align: 'right'
         },
         {
            text: 'Pago Extemporaneo',
            value: 'totalPagarMora',
            class: 'titulo',
            align: 'right'
         },
         { text: 'NPE', value: 'npe', class: 'titulo' },
         {
            text: 'Estado',
            value: 'estado',
            class: 'titulo',
            align: 'right'
         }
      ],

      // Variables de formularios
      dialogEditarCuota: false,
      dialogMasivo: false,
      doing: '',
      dialogReporteLoading: false,
      btnDetalleColor: 'primary white--text',
      talonario: [],
      talonarios: [],
      detalleIcon: 'fa-plus',
      dialogEliminarTalonario: false,
      dialogEliminarDetalle: false,
      fecha: vm.toISOLocal(new Date()).substr(0, 10),
      fechaAnulacion: vm.toISOLocal(new Date()).substr(0, 10),
      radiosBusqueda: 'r-numeroTalonario',
      radiosOption: 'r-individual',
      search: '',
      clienteId: null,
      // Propiedades talonarios
      cmbEstadoTalonario: [],
      //talonario
      talonarioId: '',
      sedeNombre: '',
      cmbSede: [],
      alumno: '',
      carnet: '',
      configuracionPago: '',
      totalTalonario: '',
      numeroTalonario: '',
      cicloDescripcion: '',
      selectEstadoTalonario: 0,
      selectCarrera: '',
      cmbCarrera: [],
      selectDecanato: '',
      cmbDecanato: [],
      selectConfPago: '',
      cmbConfPago: [],
      selectCiclo2: '',
      cmbCiclo2: [],
      // ciclos
      selectCiclo: '',
      cmbCiclo: [],

      // Variables detalles de talonario
      detalleTalonarioId: '',
      detalles: [],
      totalPagar: '',
      totalMora: '',
      npe: '',
      numeroCuota: '',
      selectEstadoDetalle: '',
      cmbEstadoDetalle: '',

      // Otros
      read: 0,
      result: [],
      snackColor: 'primary',
      snackbar: false,
      valida: 0,
      verNuevo: 0,
      snackText: '',
      cicloNombre: '',
      ntalonario: '',
      dialogPrint: false,
      filtroCuotas: '',

      // Imagenes base64
      logo64: null,
      cda64: null,
      emptyImage: null,
      selloCancelado: null

      // Reglas de validacion de formularios marti
   }),
   computed: {
      // Titulo dinamico de toolbar
      toolbarTitle() {
         if (this.editedIndex === -1) {
            return 'Talonarios'
         } else if (this.editedIndex === 0) {
            return 'Información de Talonario'
         } else {
            return 'Generar Talonarios'
         }
      },
      // Titulo dinamico de container
      detalleTitle() {
         if (this.editedIndex === 0) {
            return 'Cuotas de Talonario'
         } else {
            return 'Agregar detalles'
         }
      },
      // Filtro de busqueda de datatable
      filteredTalonarios() {
         switch (this.radiosBusqueda) {
            case 'r-numeroTalonario':
               return this.talonarios.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.numero)
                  )
               })
               break
            case 'r-carnet':
               return this.talonarios.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.carnet)
                  )
               })
               break
            case 'r-alumno':
               return this.talonarios.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.cliente)
                  )
               })
               break
            case 'r-fecha anulación':
               return this.talonarios.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.fechaAnulacion)
                  )
               })
               break
            default:
               return this.talonarios
         }
      },
      searchComputed: {
         get() {
            return this.search
         },
         set: debounce(function (newVal) {
            this.search = newVal.trim()
         }, 500)
      }
   },
   watch: {
      selectCiclo() {
         this.listarTalonarios()
      }
   },
   created() {
      this.listarComboBoxes()
      this.listarComboBoxes()
      // Obteniendo imagenes base64 para impresiones
      this.toDataURL('/assets/logo_u_black-300.png').then((result) => {
         this.logo64 = result
      })
      this.toDataURL('/assets/CdA_black-300.png').then((result) => {
         this.cda64 = result
      })
      this.toDataURL('/assets/sello-cancelado-red-400.png').then((result) => {
         this.selloCancelado = result
      })
      this.toDataURL('/assets/empty-400.png').then((result) => {
         this.emptyImage = result
      })
   },
   methods: {
      // Metodo para recibir cuenta de MiniArticulos Picker
      recibirArticulo: (e) => {
         this.getSelectedArticulo = e
      },
      // Funcion para actualizar totales de una talonario segun ID

      // Cambia de container y muestra los detalles de una talonario
      showTalonarioDetalle(item) {
         this.editedIndex = 0
         this.editarTalonario(item)
         this.block()
         this.verNuevo = 1
      },
      // Mostrar el formulario para ingresar una nueva talonario
      mostrarNuevo() {
         this.editedIndex = 1
         this.verNuevo = 1
         this.radiosBusqueda = 'r-numeroTalonario'
      },
      getColorEstado(item) {
         switch (item.estadoId) {
            case 1:
               return 'green'
               break
            case 2:
               return 'red'
               break
            case 3:
               return 'blue'
               break

            default:
               return 'black'
               break
         }
      },

      // Regresa al container principal
      ocultarNuevo() {
         this.verNuevo = 0
         this.listarTalonarios()
         this.clear()
         this.clearDetalle()
         this.unblock()
      },
      // Si no cargaron bien las talonarios en el datatable vuelve a listar
      reset() {
         this.listarComboBoxes()
      },
      // Bloquear formulario talonarios
      block() {
         this.read = 1
      },
      // Desbloquear formulario talonarios
      unblock() {
         this.read = 0
      },

      // ------------------ LISTAR INFORMACION --------------------
      /* Metodos para listar informacion desde la API */

      // Listar las talonarios de un periodo y popula el datatable principal
      listarTalonarios() {
         let ciclo = 0
         let year = 0

         if (this.selectCiclo > 0) {
            for (let i = 0; i < this.cmbCiclo.length; i++) {
               if (this.selectCiclo == this.cmbCiclo[i].cicloId) {
                  ciclo = this.cmbCiclo[i].numero
                  year = this.cmbCiclo[i].annio
               }
            }
            axios
               .get('api/Talonarios?Year=' + year + '&NumeroCiclo=' + ciclo)
               .then((response) => {
                  this.talonarios = response.data
               })
               .catch((error) => {
                  console.log(error)
               })
         }
      },
      // Popular combobox de tipos de talonarios
      listarComboBoxes() {
         axios
            .get('api/Ciclos')
            .then((response) => {
               this.cmbCiclo = response.data
               this.cmbCiclo2 = response.data
               this.selectCiclo = this.cmbCiclo[0].cicloId
            })
            .catch((error) => {
               console.log(error)
            })
         // Obteniendo sub categorias

         axios
            .get('api/EstadoTalonario')
            .then((response) => {
               this.cmbEstadoTalonario = response.data
            })
            .catch((error) => {
               console.log(error)
            })
         axios
            .get('api/EstadoDetalleTalonario')
            .then((response) => {
               this.cmbEstadoDetalle = response.data
            })
            .catch((error) => {
               console.log(error)
            })
      },
      /// Lista los detalles de un talonario
      listardetalleTalonario() {
         this.detalles = this.talonario.detalles
      },

      // ----------------- talonarios ----------------
      /* Metodos para trabajar con talonarios */

      // Guardar talonario
      guardarTalonario() {
         if (this.talonarioId > 0) {
            // Editar informacion de talonario desde dialog
            axios
               .put(
                  'api/colecturia/Talonario/' +
                     this.talonarioId +
                     '?estadoId=' +
                     this.selectEstadoTalonario,
                  {}
               )
               .then(() => {
                  this.closeEditTalonario()
                  this.listarTalonarios()
                  this.snackText = 'Talonario editado exitosamente!'
                  this.snackColor = 'snackSuccess'
                  this.snackbar = true
                  this.closeGenerarTalonarios()
               })
               .catch((error) => {
                  console.log(error)
                  if (error.response.status == 304) {
                     this.closeGenerarTalonarios()
                     this.listarTalonarios()
                     this.snackText = 'Talonario no se pudo crear'
                     this.snackColor = 'snackInfo'
                     this.snackbar = true
                  }
               })
         } else {
            this.dialogReporteLoading = true
            switch (this.radiosOption) {
               case 'r-individual':
                  axios
                     .post('api/colecturia/Talonario', {
                        clienteId: this.clienteId,
                        configuracionPagoId: this.selectConfPago,
                        cicloId: this.selectCiclo2
                     })
                     .then(() => {
                        this.dialogReporteLoading = false
                        this.snackColor = 'snackSuccess'
                        this.snackText = 'Talonario creado exitosamente!'
                        this.snackbar = true
                        this.listarTalonarios()

                        this.closeGenerarTalonarios()
                        this.block()
                     })
                     .catch((error) => {
                        console.log(error)
                        if (
                           error.response.status == 500 ||
                           error.response.status == 400
                        ) {
                           this.dialogReporteLoading = false
                           this.closeGenerarTalonarios()
                           this.listarTalonarios()
                           this.snackText = 'Talonario no se pudo crear'
                           this.snackColor = 'snackInfo'
                           this.snackbar = true
                        }
                     })
                  break
               case 'r-carrera':
                  axios
                     .post('api/colecturia/Talonario/por-carrera', {
                        carreraId: this.selectCarrera,
                        cicloId: this.selectCiclo2
                     })
                     .then(() => {
                        this.dialogReporteLoading = false
                        this.snackColor = 'snackSuccess'
                        this.snackText = 'Talonarios creados exitosamente!'
                        this.snackbar = true
                        this.listarTalonarios()
                        this.closeGenerarTalonarios()
                     })
                     .catch((error) => {
                        console.log(error)
                        if (
                           error.response.status == 500 ||
                           error.response.status == 400
                        ) {
                           this.dialogReporteLoading = false
                           this.closeGenerarTalonarios()
                           this.listarTalonarios()
                           this.snackText = 'Talonarios no se  crearon'
                           this.snackColor = 'snackInfo'
                           this.snackbar = true
                        }
                     })
                  break
               case 'r-decanato':
                  axios
                     .post('api/colecturia/Talonario/por-facultad', {
                        facultadId: this.selectDecanato,
                        cicloId: this.selectCiclo2
                     })
                     .then(() => {
                        this.dialogReporteLoading = false
                        this.snackColor = 'snackSuccess'
                        this.snackText = 'Talonarios creados exitosamente!'
                        this.snackbar = true
                        this.listarTalonarios()
                        this.closeGenerarTalonarios()
                     })
                     .catch((error) => {
                        console.log(error)
                        if (
                           error.response.status == 500 ||
                           error.response.status == 400
                        ) {
                           this.dialogReporteLoading = false
                           this.closeGenerarTalonarios()
                           this.listarTalonarios()
                           this.snackText = 'Talonarios no se  crearon'
                           this.snackColor = 'snackInfo'
                           this.snackbar = true
                        }
                     })
                  break
            }
            this.dialogMasivo = false
            this.clearGenerando()
         }
      },
      // Muestra dialog de edicion de talonario o carga la informacion de una talonario en las variables locales
      editarTalonario(item, yes) {
         this.talonarioId = item.talonarioId
         axios
            .get('api/Talonarios/' + this.talonarioId)
            .then((response) => {
               this.talonario = response.data
               this.fecha = this.parseDate(this.talonario.fecha)
               this.fechaAnulacion = this.parseDate(
                  this.talonario.fechaAnulacion
               )
               this.totalTalonario = this.talonario.totalTalonario
               this.nuthisroTalonario = this.talonario.nuthisro
               this.carnet = this.talonario.carnet
               this.cicloDescripcion = this.talonario.ciclo
               this.alumno = this.talonario.alumno
               this.sedeNombre = this.talonario.sedeNombre
               this.selectEstadoTalonario = this.talonario.estadoId
               this.detalles = this.talonario.detalles
               // Si esta en el container principal muestra el dialog de edicion de talonario
               if (this.editedIndex == -1 && !yes) {
                  this.dialogEditar = true
               }
            })
            .catch((error) => {
               console.log(error)
            })
      },
      // Elimina una talonario
      removerTalonario() {
         /* Solo se podran eliminar talonarios si el periodo contable/anual
			   esta abierto y si la talonario esta en proceso. */
         switch (this.radiosOption) {
            case 'r-individual':
               if (this.selectEstadoTalonario == 0) {
                  axios
                     .delete('api/colecturia/Talonario/' + this.talonarioId)
                     .then(() => {
                        this.listarTalonarios()
                        this.closeDeleteTalonario()
                        this.snackText = 'talonario removida exitosamente!'
                        this.snackColor = 'snackSuccess'
                        this.snackbar = true
                        this.closeGenerarTalonarios()
                     })
                     .catch((error) => {
                        console.log(error)
                     })
               } else {
                  this.snackText =
                     'Solo se pueden remover talonarios en proceso.'
                  this.snackColor = 'snackWarning'
                  this.snackbar = true
               }
               break
            case 'r-carrera':
               this.dialogReporteLoading = true
               axios
                  .delete(
                     'api/colecturia/Talonario/por-carrera?CarreraId=' +
                        this.selectCarrera +
                        '&CicloId=' +
                        this.selectCiclo2
                  )
                  .then(() => {
                     this.listarTalonarios()
                     this.closeDeleteTalonario()
                     this.snackText = 'talonario removida exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                     this.dialogReporteLoading = false
                     this.closeGenerarTalonarios()
                  })
                  .catch((error) => {
                     console.log(error)
                     this.dialogReporteLoading = false
                     this.listarTalonarios()
                     this.closeDeleteTalonario()
                     this.closeGenerarTalonarios()
                     this.snackText = 'Nose pudo Eliminar los Talonarios'
                     this.snackColor = 'error'
                     this.snackbar = true
                  })
               break
            case 'r-decanato':
               this.dialogReporteLoading = true
               axios
                  .delete(
                     'api/colecturia/Talonario/por-facultad?FacultadId=' +
                        this.selectDecanato +
                        '&CicloId=' +
                        this.selectCiclo2
                  )
                  .then(() => {
                     this.listarTalonarios()
                     this.closeDeleteTalonario()
                     this.closeGenerarTalonarios()
                     this.snackText = 'talonarios removidos exitosamente!'
                     this.snackColor = 'snackSuccess'
                     this.snackbar = true
                     this.dialogReporteLoading = false
                  })
                  .catch((error) => {
                     console.log(error)
                  })
               break

            default:
               break
         }

         this.dialogMasivo = false
      },
      // Muestra el dialogo de eliminar una talonario
      showDeleteTalonario(item) {
         this.dialogEliminarTalonario = true
         this.talonarioId = item.talonarioId
         this.alumno = item.cliente
         this.ntalonario = item.numero
         this.carnet = item.carnet
         this.cicloNombre = item.ciclo
         this.configuracionPago = item.configuracionPago
      },
      // Cerrar el dialogo de eliminar una talonario
      closeDeleteTalonario() {
         this.dialogEliminarTalonario = false
         this.clear()
      },
      // Cerrar dialog de edicion de talonario
      closeEditTalonario() {
         this.dialogEditar = false
         this.clear()
      },
      // Cerrar dialog de generacion de partidas
      closeGenerarPartida() {
         this.dialogGenerarPartidas = false
         this.fechaInicial = this.toISOLocal(new Date()).substr(0, 10)
         this.fechaFinal = this.toISOLocal(new Date()).substr(0, 10)
         this.checkRangoFechas = false
      },

      // Limpiar formulario de talonario y resetea las variables generales
      clear() {
         this.talonario = []
         this.talonarioId = ''
         this.editedIndex = -1
         this.configuracionPago = ''
         this.totalTalonario = ''
         this.numeroTalonario = ''
         this.sedeNombre = ''
         this.selectEstadoTalonario = ''
         this.carnet = ''
         this.alumno = ''
         this.ntalonario = ''
         this.cicloNombre = ''
      },
      // Limpiar formulario de detalle y datatable
      clearDetalle() {
         try {
            this.$refs.MiniClientes.reset()
         } catch (error) {
            // Do nothing
         }
         this.clienteId = null
         this.npe = ''
         this.numeroCuota = ''
         this.selectEstadoDetalle = ''
         this.totalPagar = ''
         this.totalMora = ''
      },
      closeCuotaPago() {
         this.dialogEditarCuota = false
         this.clearDetalle()
      },

      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      closeGenerarTalonarios() {
         this.selectCarrera = ''
         this.selectAlumno = ''
         this.selectDecanato = ''
         this.selectCiclo2 = ''
         this.selectConfPago = ''
         this.radiosOption = 'r-individual'
      },
      showMasivo(val) {
         this.dialogMasivo = true
         this.doing = val
      },
      titleMasivo() {
         return this.doing == 1
            ? 'Esta seguro que desea Crear Talonarios'
            : 'Esta Seguro que desea Eliminar Talonarios Masivos'
      },
      clearGenerando() {
         try {
            this.$refs.MiniClientes.reset()
         } catch (error) {
            // Do nothing
         }
         this.selectDecanato = ''
         this.selectCarrera = ''
         this.selectConfPago = ''
         this.selectCiclo2 = ''
      },
      printTalonario(item) {
         this.dialogPrint = true
         this.editarTalonario(item, true)
      },
      generarReporteTalonario(mode, portadas = true, filtroCuotas = null) {
         var filteredDetalles = []

         // Filtrando cuotas si es solicitado
         if (filtroCuotas) {
            var res = filtroCuotas.split(',').map(function (item) {
               return item.trim()
            })
            filteredDetalles = this.detalles.filter((el) =>
               res.includes(el.numeroCuota.toString())
            )
            filteredDetalles =
               filteredDetalles.length == 0 ? this.detalles : filteredDetalles
         } else {
            filteredDetalles = this.detalles
         }

         const getDetalleAranceles = (item) => {
            var result = []
            var fS = item.aranceles.length < 5 ? 8 : 7
            item.aranceles.forEach((arancel) => {
               result.push([
                  { text: arancel.codigo, alignment: 'right', fontSize: fS },
                  { text: arancel.nombre, fontSize: fS },
                  { text: '$', fontSize: fS },
                  {
                     text: arancel.precio,
                     fontSize: fS,
                     margin: [0, 0, 35, 0],
                     alignment: 'right'
                  }
               ])
            })
            result.push([
               {
                  text: 'TOTAL A PAGAR',
                  fontSize: 10,
                  margin: [0, 0, 15, 0],
                  bold: true,
                  alignment: 'right',
                  colSpan: 2
               },
               {},
               {
                  text: '$',
                  fontSize: 10,
                  bold: true
               },
               {
                  text: item.totalPagar,
                  fontSize: 10,
                  bold: true,
                  margin: [0, 0, 35, 0],
                  alignment: 'right'
               }
            ])
            return result
         }

         const getTalonarioBody = (showPortadas) => {
            var resultPage = []

            // ------------------------------------ PORTADA ----------------------------------------
            if (showPortadas) {
               resultPage.push([
                  {
                     table: {
                        widths: ['*', 120, '*'],
                        body: [
                           [
                              {
                                 text: 'UNIVERSIDAD CATÓLICA DE EL SALVADOR',
                                 font: 'TimesNewRoman',
                                 fontSize: 20,
                                 bold: true,
                                 alignment: 'center',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'NUESTRA MISIÓN',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              },
                              {
                                 image: this.logo64,
                                 alignment: 'center',
                                 rowSpan: 2,
                                 width: 90
                              },
                              {
                                 text: 'NUESTRA VISIÓN',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              }
                           ],
                           [
                              {
                                 text:
                                    'La formación de personas, inspirada en los principios cristianos y en los conocimientos' +
                                    ' técnicos y científicos, orientada a una constante búsqueda de la verdad y del uso del saber,' +
                                    ' para contribuir a la tutela y desarrollo de la dignidad humana y de la sociedad,' +
                                    ' mediante la investigación, docencia y la proyección social.',
                                 alignment: 'justify'
                              },
                              {},
                              {
                                 text:
                                    'Seremos una universidad con excelentes servicios de educación superior, líderes en la' +
                                    ' formación integral de la persona humana y de la sociedad, dentro de las' +
                                    ' exigencias de la verdad y del bien común.',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 table: {
                                    widths: [150, 60, '*', 90],
                                    body: [
                                       [
                                          {},
                                          {
                                             text: 'CARNET:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.carnet
                                          },
                                          {
                                             image: this.cda64,
                                             alignment: 'center',
                                             rowSpan: 4,
                                             width: 90
                                          }
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'NOMBRE:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.alumno
                                          },
                                          {}
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'FACULTAD:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.facultadNombre
                                          },
                                          {}
                                       ],
                                       [
                                          {},
                                          {
                                             text: 'CARRERA:',
                                             bold: true
                                          },
                                          {
                                             text: this.talonario.carreraNombre
                                          },
                                          {}
                                       ]
                                    ]
                                 },
                                 layout: 'noBorders',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 16,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text:
                                    'TALONARIO UNICAMENTE PARA CICLO: ' +
                                    this.talonario.ciclo,
                                 alignment: 'center',
                                 fontSize: '20',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'Talonario No. ' + this.talonario.numero,
                                 fontSize: 10,
                                 alignment: 'right',
                                 bold: true,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     colSpan: 2
                  },
                  {}
               ])
            }

            // ------------------------------------ CUOTAS ----------------------------------------
            filteredDetalles.forEach((cuota) => {
               resultPage.push([
                  [
                     {
                        table: {
                           widths: [45, '*', '*'],
                           body: [
                              [
                                 {
                                    text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                    font: 'TimesNewRoman',
                                    fontSize: 10,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 'CARNET:',
                                 {
                                    text: this.talonario.carnet,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'ALUMNO:',
                                 {
                                    text: this.talonario.alumno,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CARRERA:',
                                 {
                                    text: this.talonario.carreraNombre,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CICLO:',
                                 { text: this.talonario.ciclo },
                                 {
                                    text: [
                                       { text: 'Pago N° ' },
                                       {
                                          text: cuota.numeroCuota,
                                          fontSize: 18,
                                          bold: true
                                       }
                                    ]
                                 }
                              ],
                              [
                                 {
                                    text:
                                       'FACULTAD:\n' +
                                       this.talonario.facultadNombre,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [60, 120, 5, '*'],
                                       body: getDetalleAranceles(cuota)
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: '_',
                                    color: 'white',
                                    fontSize: 6,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [15, 150, 110],
                                       body: [
                                          [
                                             {},
                                             {
                                                text: 'FECHA VENCIMIENTO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: cuota.fechaVencimiento,
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ],
                                          [
                                             {},
                                             {
                                                text: 'PAGO EXTEMPORANEO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: this.toFixedRegex(
                                                   Number(cuota.totalPagar) +
                                                      Number(
                                                         cuota.totalPagarMora
                                                      ),
                                                   2
                                                ),
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ]
                                       ]
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'NPE',
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'right'
                                 },
                                 {
                                    text: cuota.npe,
                                    fontSize: 9,
                                    bold: true,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 {
                                    text: Base64.decode(
                                       cuota.codigoDeBarrasCode128C
                                    ),
                                    font: 'CodigoDeBarra',
                                    colSpan: 3,
                                    fontSize: 10,
                                    alignment: 'center'
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: cuota.codigoDeBarras,
                                    alignment: 'center',
                                    fontSize: 7,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       'Talonario No. ' + this.talonario.numero,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'ALUMNO',
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              // SELLO CANCELADO
                              [
                                 {
                                    image:
                                       cuota.estadoId == 3
                                          ? this.selloCancelado
                                          : this.emptyImage,
                                    absolutePosition: {
                                       x: 72,
                                       y: cuota.numeroCuota == 1 ? 258 : 212
                                    },
                                    width: 170
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       cuota.estadoId == 3
                                          ? cuota.fechaPago
                                          : '',
                                    bold: true,
                                    color: '#d9001d',
                                    fontSize: 14,
                                    absolutePosition: {
                                       x: 120,
                                       y: cuota.numeroCuota == 1 ? 312 : 266
                                    }
                                 },
                                 {},
                                 {}
                              ]
                           ]
                        },
                        margin: [0, 0, 15, 0],
                        layout: 'noBorders'
                     }
                  ],
                  [
                     {
                        table: {
                           widths: [45, '*', '*'],
                           body: [
                              [
                                 {
                                    text: 'UNIVERSIDAD CATÓLICA\nDE EL SALVADOR',
                                    font: 'TimesNewRoman',
                                    fontSize: 10,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 'CARNET:',
                                 {
                                    text: this.talonario.carnet,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'ALUMNO:',
                                 {
                                    text: this.talonario.alumno,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CARRERA:',
                                 {
                                    text: this.talonario.carreraNombre,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 'CICLO:',
                                 { text: this.talonario.ciclo },
                                 {
                                    text: [
                                       { text: 'Pago N° ' },
                                       {
                                          text: cuota.numeroCuota,
                                          fontSize: 18,
                                          bold: true
                                       }
                                    ]
                                 }
                              ],
                              [
                                 {
                                    text:
                                       'FACULTAD:\n' +
                                       this.talonario.facultadNombre,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [60, 120, 5, '*'],
                                       body: getDetalleAranceles(cuota)
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: '_',
                                    color: 'white',
                                    fontSize: 6,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    table: {
                                       widths: [15, 150, 110],
                                       body: [
                                          [
                                             {},
                                             {
                                                text: 'FECHA VENCIMIENTO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: cuota.fechaVencimiento,
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ],
                                          [
                                             {},
                                             {
                                                text: 'PAGO EXTEMPORANEO',
                                                fontSize: 10,
                                                bold: true
                                             },
                                             {
                                                text: this.toFixedRegex(
                                                   Number(cuota.totalPagar) +
                                                      Number(
                                                         cuota.totalPagarMora
                                                      ),
                                                   2
                                                ),
                                                fontSize: 10,
                                                bold: true,
                                                margin: [0, 0, 45, 0],
                                                alignment: 'right'
                                             }
                                          ]
                                       ]
                                    },
                                    layout: 'noBorders',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'NPE',
                                    fontSize: 9,
                                    bold: true,
                                    alignment: 'right'
                                 },
                                 {
                                    text: cuota.npe,
                                    fontSize: 9,
                                    bold: true,
                                    colSpan: 2
                                 },
                                 {}
                              ],
                              [
                                 {
                                    text: Base64.decode(
                                       cuota.codigoDeBarrasCode128C
                                    ),
                                    font: 'CodigoDeBarra',
                                    colSpan: 3,
                                    fontSize: 10,
                                    alignment: 'center'
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: cuota.codigoDeBarras,
                                    alignment: 'center',
                                    fontSize: 7,
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       'Talonario No. ' + this.talonario.numero,
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text: 'UNIVERSIDAD',
                                    bold: true,
                                    alignment: 'center',
                                    colSpan: 3
                                 },
                                 {},
                                 {}
                              ],
                              // SELLO CANCELADO
                              [
                                 {
                                    image:
                                       cuota.estadoId == 3
                                          ? this.selloCancelado
                                          : this.emptyImage,
                                    absolutePosition: {
                                       x: 302 + 72,
                                       y: cuota.numeroCuota == 1 ? 258 : 212
                                    },
                                    width: 170
                                 },
                                 {},
                                 {}
                              ],
                              [
                                 {
                                    text:
                                       cuota.estadoId == 3
                                          ? cuota.fechaPago
                                          : '',
                                    bold: true,
                                    color: '#d9001d',
                                    fontSize: 14,
                                    absolutePosition: {
                                       x: 302 + 120,
                                       y: cuota.numeroCuota == 1 ? 312 : 266
                                    }
                                 },
                                 {},
                                 {}
                              ]
                           ]
                        },
                        margin: [15, 0, 0, 0],
                        layout: 'noBorders'
                     }
                  ]
               ])
            })

            // ------------------------------------ REVERSO ----------------------------------------
            if (showPortadas) {
               resultPage.push([
                  {
                     table: {
                        widths: ['*', 175, '*'],
                        body: [
                           [
                              {
                                 text: 'TALONARIO DE PAGOS',
                                 font: 'TimesNewRoman',
                                 fontSize: 20,
                                 bold: true,
                                 alignment: 'center',
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'INSTRUCCIONES',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              },
                              {
                                 image: this.logo64,
                                 alignment: 'center',
                                 width: 90,
                                 rowSpan: 2
                              },
                              {
                                 text: 'RECUERDE QUE',
                                 bold: true,
                                 italics: true,
                                 alignment: 'center',
                                 decoration: 'underline'
                              }
                           ],
                           [
                              {
                                 text:
                                    '1.    Los pagos deberán ser cancelados en el Banco que la Universidad autorice para realizar' +
                                    ' los cobros de los alumnos.',
                                 alignment: 'justify'
                              },
                              {},
                              {
                                 text:
                                    '1.    El vencimiento de las cuotas está programado en su talonario de pago y en el calendario' +
                                    ' académico. (Art. 29 del reglamento de evaluación).',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text:
                                    '2.    Es responsabilidad del alumno verificar que el cajero firme y selle los recibos' +
                                    ' correspondiente a los pagos que realice en el Banco',
                                 alignment: 'justify'
                              },
                              {
                                 text: 'UNIVERSIDAD CATÓLICA DE EL SALVADOR',
                                 font: 'TimesNewRoman',
                                 bold: true,
                                 alignment: 'center',
                                 fontSize: 13
                              },
                              {
                                 text:
                                    '2.    Las matriculas y las cuotas canceladas en fechas posteriores a el vencimiento tendrán' +
                                    ' complemento de $12.00 y $12.00 respectivamente dicha medida será aplicada por el Banco automáticamente.',
                                 alignment: 'justify'
                              }
                           ],
                           [
                              {
                                 text:
                                    '\n3.    En caso de extravío del talonario de pagos deberá de solicitar una reposición' +
                                    ' en la colecturía de la Universidad, previo a pago respectivo.',
                                 alignment: 'justify'
                              },
                              {
                                 text:
                                    'By pass a Metapán y Carretera antigua a San Salvador\n' +
                                    'PBX: (503) 2484-0600\nFax: (503) 2441-2655,\nSanta Ana, El Salvador\n' +
                                    'Web: http://www.catolica.edu.sv\nE-mail: catolica@catolica.edu.sv',
                                 alignment: 'center',
                                 bold: true,
                                 fontSize: 11
                              },
                              { text: '', alignment: 'justify' }
                           ],
                           [
                              {
                                 text: '_',
                                 color: 'white',
                                 fontSize: 20,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ],
                           [
                              {
                                 text: 'Talonario No. ' + this.talonario.numero,
                                 fontSize: 10,
                                 alignment: 'right',
                                 bold: true,
                                 colSpan: 3
                              },
                              {},
                              {}
                           ]
                        ]
                     },
                     layout: 'noBorders',
                     pageBreak: 'before',
                     colSpan: 2
                  },
                  {}
               ])
            }

            return resultPage
         }

         var dd = {
            pageSize: {
               width: 612.0,
               height: 396.0
            },
            background: function () {
               return {
                  canvas: [
                     {
                        // COLOR DE PAGINA
                        type: 'rect',
                        x: 0,
                        y: 0,
                        w: 612.0,
                        h: 396.0,
                        color: '#FFF'
                     }
                  ]
               }
            },
            pageMargins: [20, 10],
            content: [
               {
                  table: {
                     heights: 390.0,
                     widths: ['*', '*'],
                     body: getTalonarioBody(portadas)
                  },
                  layout: {
                     hLineWidth: function () {
                        return 0
                     },
                     vLineWidth: function (i) {
                        return i === 1 ? 1 : 0
                     },
                     vLineColor: function () {
                        return 'gray'
                     },
                     vLineStyle: function () {
                        return { dash: { length: 6 } }
                     }
                  }
               }
            ],
            defaultStyle: {
               fontSize: 9
            }
         }

         var filename = 'Talonario.pdf'
         getReporteLib().then((res) => res.generarPDF(mode, dd, filename))
      },
      decodeString() {
         this.encoded64 = Base64.decode(this.encoded64)
      },
      toDataURL(url) {
         return new Promise(function (resolve) {
            var xhr = new XMLHttpRequest()
            xhr.onload = function () {
               var reader = new FileReader()
               reader.onloadend = function () {
                  resolve(reader.result)
               }
               reader.readAsDataURL(xhr.response)
            }
            xhr.open('GET', url)
            xhr.responseType = 'blob'
            xhr.send()
         })
      },
      closeDialogPrint() {
         this.dialogPrint = false
         this.clear()
      }
   }
}
</script>
